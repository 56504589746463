<p-dialog
  header="{{ orderDetail.orderNumber }}"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '35rem' }"
>
  <hr class="divider" />
  <div class="dialog-inputs">
    <div class="field">
      <label for="inspector">Prüfende Person</label>
      <p-multiSelect
        appendTo="body"
        inputId="inspector"
        [options]="inspectorOptions"
        [(ngModel)]="selectedInspectorIds"
        optionLabel="name"
        optionValue="id"
        placeholder="Keine zugewiesen"
        display="chip"
       [style]="{'min-width':'280px'}"
      />
    </div>
    <div class="field">
      <label for="date">Startdatum</label>
      <p-calendar
        appendTo="body"
        inputId="date"
        [(ngModel)]="selectedStartDate"
        [readonlyInput]="true"
        [showButtonBar]="true"
        [iconDisplay]="'input'"
        [showIcon]="true"
        placeholder="Datum"
        [keepInvalid]="true"
        [minDate]="minDate"
        [maxDate]="selectedEndDate || null"
        dateFormat="dd.mm.yy"
        [style]="{'min-width':'280px'}"
        [disabled]="
          orderDetail.status !== OrderStatus.Created &&
          orderDetail.status !== OrderStatus.Planned
        "
      />
    </div>
    <div class="field">
      <label for="date">Enddatum</label>
      <p-calendar
        appendTo="body"
        inputId="endDate"
        [(ngModel)]="selectedEndDate"
        [readonlyInput]="true"
        [showButtonBar]="true"
        [iconDisplay]="'input'"
        [showIcon]="true"
        placeholder="Datum"
        [minDate]="selectedStartDate"
        dateFormat="dd.mm.yy"
        [style]="{'min-width':'280px'}"
        [keepInvalid]="true"
        [disabled]="
          orderDetail.status === OrderStatus.ReadyForShipment ||
          orderDetail.status === OrderStatus.ShippedToCustomer
        "
      />
    </div>
  </div>

  <hr class="divider" />
  <div class="dialog-buttons">
    <app-button (click)="cancel()" type="secondary-button" label="Abbrechen" />
    <app-button (click)="save()" type="primary-button" label="Speichern" />
  </div>
</p-dialog>
