import { Component} from '@angular/core';
import { ButtonComponent } from '../../../button/button.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { FileService } from '../file.service';
import { ToastService } from '../../../../../core/toast.service';

@Component({
  selector: 'app-product-listing-file-upload',
  standalone: true,
  imports: [ButtonComponent, FileUploadModule, ToastModule, CommonModule],
  templateUrl: './product-listing-file-upload.component.html',
  styleUrl: './product-listing-file-upload.component.scss',
})
export class ProductListingFileUploadComponent {
  constructor(
    private fileService: FileService,
    private toastService: ToastService
  ) {}

  onFileSelected(event: any) {
    for (const file of event.target.files) {
      if (
        file &&
        file instanceof File &&
        this.isValidExcelFileWithMacros(file)
      ) {
        this.uploadProductListingFile(file);
      } else {
        this.toastService.show(
          'warn',
          'Upload fehlgeschlagen!',
          'Es können nur Excel-Dateien hochgeladen werden.'
        );
      }
    }
  }

  uploadProductListingFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.convertProductListingExcelFile(formData).subscribe({
      next: (blob) => {
        this.handleFileDownload(blob);
        this.toastService.show(
          'success',
          'Erstellung des Anhangs erfolgreich!',
          ''
        );
      },
      // error: (error: HttpErrorResponse) => {  },
    });
  }

  private isValidExcelFileWithMacros(file: File): boolean {
    const allowedExtensions = ['.xlsm'];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf('.'))
      .toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  private handleFileDownload(response: any): void {
    const contentDispositionHeader =
      response.headers.get('Content-Disposition') || '';

    const fileName = this.fileService.extractFilename(contentDispositionHeader);
    const blob = response.body;

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }
}
