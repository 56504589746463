import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-comment-detail-card',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './comment-detail-card.component.html',
  styleUrl: './comment-detail-card.component.scss'
})
export class CommentDetailCardComponent {

  @Input() title = '';

  private isHovered = false;

  onMouseEnter(): void {
    this.isHovered = true;
  }

  onMouseLeave(): void {
    this.isHovered = false;
  }

}
