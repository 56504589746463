<p-dialog
  header="Backoffice Kommentar"
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '35rem' }"
>
  <hr class="divider" />
  <div class="dialog-inputs">
    <div class="field">
      <textarea class="full-width-textarea"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="comment">
      </textarea>
    </div>
  </div>
  <hr class="divider" />
  <div class="dialog-buttons">
    <app-button (click)="cancel()" type="secondary-button" label="Abbrechen" />
    <app-button (click)="delete()" type="tertiary-button" label="Löschen" />
    <app-button (click)="save()" type="primary-button" label="Speichern" />
  </div>
</p-dialog>
