<div
  class="card"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="card-text">
    <div class="title-text">
      <h3>{{ title }}</h3>
    </div>

    <ng-content></ng-content>
  </div>
</div>
