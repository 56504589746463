import { Component, Input, OnInit } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { OrderDetailResponse } from '../../../models/order/order-detail-response.model';
import { ChipComponent } from '../chip/chip.component';
import {
  OrderStatusLabels,
  OrderStatus,
} from '../../../models/status/order-status.enum';
import {
  HygieneInspectionStatus,
  HygieneInspectionStatusLabels,
} from '../../../models/status/hygiene-inspection-status.enum';
import { AggregateResponse } from '../../../models/aggregates/aggregate-response.model';
import { AggregateInspectionStatus } from '../../../models/status/aggregate-inspection-status.enum';

@Component({
  selector: 'app-aggregate-analysis',
  standalone: true,
  imports: [ChartModule, ChipComponent],
  templateUrl: './aggregate-analysis.component.html',
  styleUrl: './aggregate-analysis.component.scss',
})
export class AggregateAnalysisComponent implements OnInit {
  @Input() orderDetail!: OrderDetailResponse;
  data: any;

  options: any;

  ngOnInit() {
    const statusCounts = this.countStatusOccurrences(
      this.orderDetail.aggregates
    );

    this.data = {
      labels: this.getChartLabels(),
      datasets: [
        {
          data: Object.values(statusCounts),
          backgroundColor: ['#17224D', '#659CF9', '#A8C8FF', '#445184'],
          hoverBackgroundColor: ['#263880', '#709AE2', '#C5DBFF', '#55649F'],
        },
      ],
    };

    this.options = {
      cutout: '60%',
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: '#022751',
            usePointStyle: true, // Aktiviert die Punktstile für die Legende
            pointStyle: 'circle',
            font: {
              size: 16,
            },

            padding: 30,
          },
        },
      },
    };
  }

  getChartLabels(): string[] {
    const labelName: string[] = [];
    const labelStatus = [
      'Abgeschlossen',
      'Abgebrochen',
      'Im Gange',
      'Teilabgeschlossen',
    ];
    const statusCounts = Object.values(
      this.countStatusOccurrences(this.orderDetail.aggregates)
    );

    labelStatus.forEach((label, index) => {
      labelName.push(`${label}: ${statusCounts[index]}`);
    });
    return labelName;
  }

  countStatusOccurrences(aggregatesList: AggregateResponse[]): {
    [key in AggregateInspectionStatus]: number;
  } {
    const statusCount: { [key in AggregateInspectionStatus]: number } = {
      [AggregateInspectionStatus.Inspected]: 0,
      [AggregateInspectionStatus.NotInspected]: 0,
      [AggregateInspectionStatus.OnGoing]: 0,
      [AggregateInspectionStatus.PartlyInspected]: 0,
    };

    aggregatesList.forEach((aggregate) => {
      const status = aggregate.hygiene?.status;
      if (status !== undefined && status in statusCount) {
        statusCount[status as AggregateInspectionStatus]++;
      }
    });

    return statusCount;
  }

  getTotalAggregates(aggregatesList: AggregateResponse[]): number {
    return aggregatesList ? aggregatesList.length : 0;
  }

  isOrderStatus(status: any): status is OrderStatus {
    return Object.values(OrderStatus).includes(status);
  }

  getStatusLabel(status?: OrderStatus | HygieneInspectionStatus): string {
    if (status === undefined) {
      return 'Kein Status vorhanden';
    }

    if (this.isOrderStatus(status)) {
      return OrderStatusLabels[status];
    } else {
      return HygieneInspectionStatusLabels[status as HygieneInspectionStatus];
    }
  }
}
