export enum UserRole {
  BACKOFFICE_ADMIN = 'b48e7dc5-62de-4916-b517-520cdfb95ece',
  BACKOFFICE_PLANNER = '5dabc8d3-4d25-42ed-baf1-f9d63b2d9972',
  BACKOFFICE_REVIEWER_HYGIENE = '7be9cc8c-bef5-4f63-9614-322de6dd6cc2',
  BACKOFFICE_SHIPPER = '874124c4-ea86-4ab3-883b-9bab04f80c5c',
  BACKOFFICE_USER = '24700544-94ee-46b0-9c6a-59e922b00e7c',
  BACKOFFICE_SALES_ASSISTANT = '95ad4019-e16d-4d4c-906e-fa6f4d3be08c',
  INSPECTOR = 'f7f3737f-5197-4618-8cf4-5f93c08e0b2e',
  INSPECTOR_ALL_SEEING = '07672748-1ce4-417e-b82c-ee8859aa1a16',
  BACKOFFICE_STOCK_MANAGER = 'bae1fb8b-d1f4-4c43-a8b5-89ebc6faa354'
}
