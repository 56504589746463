import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
  InspectorResponse,
  InspectorsStockResponse, InspectorStockResponse,
} from '../shared/models/order/inspector-response.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { InspectorsPages } from '../shared/models/inspectors/InspectorsPages.model';
import { LoggerService } from './logger.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class InspectorService {
  private readonly _apiUrlInspectors = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/inspectors/`;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) {}

  getPaginatedInspectors(params: HttpParams): Observable<InspectorsPages> {
    return this.http
      .get<{
        content: InspectorResponse[];
        totalPages: number;
      }>(this._apiUrlInspectors, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this))
      );
  }

  getInspectorsStock(owner: string): Observable<InspectorStockResponse[]> {
    return this.http
      .get<InspectorsStockResponse>(`${this._apiUrlInspectors}stock/` + owner)
      .pipe(
        map(response => response.stocks),
        catchError(this.handleError.bind(this))
      );
  }


  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Ein unbekannter Fehler ist aufgetreten!';

    if (error.error instanceof ErrorEvent) {
      // Client-seitiger oder Netzwerkfehler
      errorMessage = `Ein Fehler ist aufgetreten: ${error.error.message}`;
    } else {
      // Backend-seitiger Fehler
      errorMessage =
        `Backend hat mit Status ${error.status} geantwortet. ` +
        `Grund dafür ist: ${error.message}`;
    }

    this.loggerService.error(errorMessage);
    this.toastService.show('warn', 'Ein Fehler ist aufgetreten', errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
