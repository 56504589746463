<div class="filters">
  <div class="filter">
    <label for="firstnName">Vorname</label>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        (keydown.enter)="filterOrders()"
        [(ngModel)]="firstName"
        id="firstnName"
        pInputText
        placeholder="Suchen"
        type="text"
      />
    </p-iconField>
  </div>
  <div class="filter">
    <label for="lastName">Nachname</label>
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        (keydown.enter)="filterOrders()"
        [(ngModel)]="lastName"
        id="lastName"
        pInputText
        placeholder="Suchen"
        type="text"
      />
    </p-iconField>
  </div>
  <div class="filter">
    <label for="active">Account Status</label>
    <p-dropdown
      [(ngModel)]="isActive"
      [options]="stateFilterItems"
      inputId="active"
      placeholder="Alle"
    ></p-dropdown>
  </div>
  <app-button (click)="filterOrders()" label="Filtern" type="primary-button" />
  <app-button
    (click)="clearFilter()"
    label="Filter zurücksetzen"
    type="secondary-button"
  />
</div>
