import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastMessage, ToastService } from '../../../../core/toast.service';
import { Mutex } from 'async-mutex';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit {
  toastMessage: ToastMessage | null = null;

  // Show next ToastMessage after the previous one was closed
  private toastMessageQueue: (ToastMessage | null)[] = [];

  // mutex to synchronize the access to toastMessageQueue
  private mutex = new Mutex();

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.message$.subscribe((toast) => {
      this.mutex.runExclusive(() => {
        if (this.toastMessage == null) {
          // no toast is shown => directly display the new one
          this.toastMessage = toast;
        } else {
          // other toast is currently displayed => put into queue for later
          this.toastMessageQueue.push(toast);
        }
      });
    });
  }

  closeToast() {
    this.mutex.runExclusive(() => {
      if (this.toastMessageQueue.length > 0) {
        // other toast is waiting in the queue => display the next one
        this.toastMessage = this.toastMessageQueue.shift() || null;
      } else {
        // queue is empty, just clear the current toast
        this.toastMessage = null;
      }
    });
  }
}
