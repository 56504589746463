import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component, DoCheck,
  EventEmitter,
  HostListener,
  Input, IterableDiffers,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChipComponent } from '../chip/chip.component';

import { AggregateResponse } from '../../../models/aggregates/aggregate-response.model';
import { CommonModule } from '@angular/common';
import { FileService } from '../file-services/file.service';

import { AggregateType, AggregateTypeLabels } from '../../../models/aggregates/aggregate-type.enum';
import { CommentType } from '../../../models/comments/comments-type.enum';
import { AggregateReportState } from '../../../models/status/aggregate-report-state.enum';
import { OrderDetailResponse } from '../../../models/order/order-detail-response.model';
import { OrderStatus } from '../../../models/status/order-status.enum';
import { TabViewModule } from 'primeng/tabview';
import { FieldsetModule } from 'primeng/fieldset';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';
import { CommentDetailCardComponent } from '../comment-detail-card/comment-detail-card.component';
import { ButtonComponent } from '../../button/button.component';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
import { AggregateInspectionStatus } from '../../../models/status/aggregate-inspection-status.enum';
import { AccordionModule } from 'primeng/accordion';
import { RemuneratedTaskHygieneAggregateType } from '../../../models/aggregates/remunerate-tasks-hygiene-type.enum';

@Component({
  selector: 'app-detail-table',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    ChipComponent,
    CommonModule,
    TabViewModule,
    FieldsetModule,
    CommentDetailCardComponent,
    CommentDialogComponent,
    ButtonComponent,
    NotificationCardComponent,
    AccordionModule,
  ],
  templateUrl: './detail-table.component.html',
  styleUrls: ['./detail-table.component.scss'],
})
export class DetailTableComponent implements OnInit, DoCheck {
  @Input() orderDetail: OrderDetailResponse | undefined;
  @Input() aggregates: AggregateResponse[] = [];

  @Output() requestParentReload = new EventEmitter<void>();

  @ViewChild(CommentDialogComponent)
  commentDialogComponent!: CommentDialogComponent;

  remuneratedTaskTypeLabels = RemuneratedTaskHygieneAggregateType;

  sortedData: AggregateResponse[] = this.aggregates;
  sortColumn = '';
  sortDirection: 'asc' | 'desc' | '' = '';
  OrderStatus = OrderStatus;
  AggregateReportState = AggregateReportState;
  error: Error | null = null;

  commentType = CommentType;

  showComment: Record<string, boolean> = {};
  activeTabs: Record<string, string> = {};
  showDetails: Record<string, boolean> = {};
  columns = [
    { key: 'idrId', label: 'IDR-ID', align: 'left' },
    { key: 'name', label: 'Anlagenname', align: 'left' },
    { key: 'type', label: 'Typ', align: 'left' },
    { key: 'buildingName', label: 'Aufstellungsort', align: 'left' },
    { key: 'customerProvidedId', label: 'Kunden-ID', align: 'left' },
    { key: 'hygiene.inspectionDate', label: 'Insp.-Datum', align: 'left' },
    { key: 'hygiene.status', label: 'Insp.-Status', align: 'center' },
    { key: 'aggregateReport.reportId', label: 'Bericht', align: 'center' },
  ];
  protected readonly AggregateInspectionStatus = AggregateInspectionStatus;
  private aggregatesDiffer: any;
  private openDropdownId: number | null = null;

  constructor(
    private fileService: FileService,
    private iterableDiffers: IterableDiffers,
    private appRef: ApplicationRef
  ) {
    this.aggregatesDiffer = this.iterableDiffers.find([]).create();
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngDoCheck(): void {
    const changes = this.aggregatesDiffer.diff(this.aggregates);
    if (changes) {
      this.updateAggregateArray();
      this.updateData();
    }
  }

  updateAggregateArray(): void {
    this.aggregates = [...this.aggregates];
  }

  toggleComment(id: string): void {
    this.showComment[id] = !this.showComment[id];
    if (!this.activeTabs[id]) {
      this.activeTabs[id] = 'comments';
    }
    if (this.showDetails[id]) {
      this.showDetails[id] = false;
    }
  }

  toggleDetails(aggregate: AggregateResponse): void {
    if (this.hasCancelReason(aggregate) || this.hasValidTasks(aggregate)) {
      this.showDetails[aggregate.id] = !this.showDetails[aggregate.id];
      if (this.showComment[aggregate.id]) {
        this.showComment[aggregate.id] = false;
      }
    }
  }

  getCommentImage(aggregate: AggregateResponse): string {
    return aggregate.noteInternalBackoffice ||
      aggregate.noteInternalInspector ||
      aggregate.hygiene?.noteExternalReport ||
      aggregate.hygiene?.noteInternalBackoffice ||
      aggregate.hygiene?.noteInternalInspector
      ? '/assets/comment.svg'
      : '/assets/no_comment.svg';
  }

  getInfoImage(aggregate: AggregateResponse): string {
    return this.hasCancelReason(aggregate) || this.hasValidTasks(aggregate)
      ? '/assets/info.svg'
      : '/assets/no_info.svg';
  }

  downloadSingleReport(aggregateId: string): void {
    this.fileService
      .downloadSingleHygieneAggregateReport(aggregateId)
      .subscribe((response) => {
        this.handleFileDownload(response);
      });
  }

  updateData(): void {
    const sortedData = [...this.aggregates];

    if (this.sortColumn) {
      sortedData.sort((a, b) => {
        const aVal = this.getNestedValue(a, this.sortColumn);
        const bVal = this.getNestedValue(b, this.sortColumn);

        const aValue = aVal !== null && aVal !== undefined ? aVal : '';
        const bValue = bVal !== null && bVal !== undefined ? bVal : '';

        if (aValue === '' && bValue !== '') {
          return 1;
        }
        if (aValue !== '' && bValue === '') {
          return -1;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return this.sortDirection === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return this.sortDirection === 'asc'
            ? aValue - bValue
            : bValue - aValue;
        }

        return 0;
      });
    }

    this.sortedData = sortedData;
  }

  setSort(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection =
        this.sortDirection === 'asc'
          ? 'desc'
          : this.sortDirection === 'desc'
            ? ''
            : 'asc';
      if (this.sortDirection === '') {
        this.sortColumn = '';
      }
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    this.updateData();
  }

  getLabel(type: AggregateType): string {
    return AggregateTypeLabels[type];
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach((dropdown) => {
      const button = dropdown.querySelector('.dropdown-toggle') as HTMLElement;
      const menu = dropdown.querySelector('.dropdown-menu') as HTMLElement;

      if (
        button &&
        menu &&
        !button.contains(target) &&
        !menu.contains(target)
      ) {
        this.openDropdownId = null;
      }
    });
  }

  openCommentDialog(id: string, comment: string | undefined, type: string) {
    this.commentDialogComponent.showDialog(id, comment, type);
  }

  updateDetailOnChange() {
    this.requestParentReload.emit();
  }

  getTaskLabel(taskType: string): string {
    return this.remuneratedTaskTypeLabels[taskType as keyof typeof this.remuneratedTaskTypeLabels] || taskType;
  }

  hasValidTasks(aggregate: AggregateResponse): boolean {
    return aggregate.hygiene?.remuneratedTasks?.some(task => task.type?.trim()) ?? false;
  }

  hasCancelReason(aggregate: AggregateResponse): boolean {
    return !!(aggregate.hygiene?.cancelReason || aggregate.hygiene?.cancelReasonComment);
  }

  private handleFileDownload(response: any): void {
    const contentDispositionHeader =
      response.headers.get('Content-Disposition') || '';

    const fileName = this.fileService.extractFilename(contentDispositionHeader);

    const blob = response.body;

    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }

  private getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((o, p) => {
      return o ? o[p] : undefined;
    }, obj);
  }
}
