import { Component, OnInit } from '@angular/core';
import { ErrorComponent } from '../../shared/components/orders/error/error.component';
import { LoadingAnimationComponent } from '../../shared/components/loading-animation/loading-animation.component';
import { InspectorTableComponent } from '../../shared/components/inspectors/inspector-table/inspector-table.component';
import { UserRole } from '../../core/user-role.enum';
import { ActivatedRoute, Params } from '@angular/router';
import { QueryParamsService } from '../../core/query-params.service';
import { LoggerService } from '../../core/logger.service';
import { InspectorResponse } from '../../shared/models/order/inspector-response.model';
import { HttpParams } from '@angular/common/http';
import { finalize } from 'rxjs';
import { InspectorService } from '../../core/inspector.service';
import {
  InspectorFilterComponent
} from '../../shared/components/inspectors/inspector-filter/inspector-filter.component';

@Component({
  selector: 'app-inspector',
  standalone: true,
  imports: [
    ErrorComponent,
    LoadingAnimationComponent,
    InspectorTableComponent,
    InspectorFilterComponent,
  ],
  templateUrl: './inspector.component.html',
  styleUrl: './inspector.component.scss',
})
export class InspectorComponent implements OnInit {
  isLoading = true;
  error: Error | null = null;

  inspectorsList: InspectorResponse[] = [];
  totalPages = 0;
  currentPage = 0;
  pageSize = 25;
  filterValues?: Params;
  systemNamesList: string[] = [];
  protected readonly UserRole = UserRole;

  constructor(
    private inspectorService: InspectorService,
    private route: ActivatedRoute,
    private queryService: QueryParamsService,
    private loggerService: LoggerService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.handleQueryParams(params);
    });
  }

  filterAllInspectors(filter: HttpParams) {
    this.isLoading = true;
    this.inspectorService
      .getPaginatedInspectors(filter)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.loggerService.log('Fetch filtered order list completed');
          this.inspectorsList = data.content;
          this.systemNamesList = data.content.map(inspector => inspector.systemName)
          this.totalPages = data.totalPages;

          this.queryService.updateQueryParams(filter);
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.loggerService.error('Error filtering order list');
          this.error = error;
        },
      });
  }

  private handleQueryParams(params: Params): void {
    const httpParams = this.queryService.convertQueryParamsToHttpParams(params);

    if (Object.keys(params).length > 0) {
      this.loggerService.log('Retrieving filter params from url');
      this.filterValues = params;
      this.filterAllInspectors(httpParams);
    } else {
      this.setInitialQueryParams();
    }
  }

  private setInitialQueryParams(): void {
    this.queryService.updateQueryParams(
      new HttpParams().set('page', this.currentPage).set('size', this.pageSize).set('active', true)
    );
  }
}
