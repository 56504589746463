<div
  (blur)="toggleSidebar(false)"
  (focus)="toggleSidebar(true)"
  (mouseout)="toggleSidebar(false)"
  (mouseover)="toggleSidebar(true)"
  [class.expanded]="isExpanded"
  class="sidebar"
  tabindex="0"
>
  <div class="sidebar-wrapper">
    <a class="logo" href="/order">
      <img alt="Logo" src="/assets/logo-white.svg" />
    </a>
    <hr />
    <div class="menu-items">
      <a class="menu-item" href="/order">
        <div class="icon"><img alt="Aufträge" src="/assets/order.svg" /></div>

        <span *ngIf="isExpanded" class="menu-text">Aufträge</span>
      </a>
      <a class="menu-item" href="/inspector">
        <div class="icon"><img alt="Techniker" src="/assets/inspector-icon.svg" /></div>

        <span *ngIf="isExpanded" class="menu-text">Techniker</span>
      </a>
    </div>
    <div *ngIf="isExpanded" class="user-login">
      <div class="icon">
        <img alt="Profil" class="profil-icon" src="/assets/profil.svg" />
      </div>
      <div class="user-info">
        <span class="user-name">{{ name }}</span>
      </div>
      <button
        (click)="logoutUser()"
        (keyup.enter)="logoutUser()"
        (keyup.space)="logoutUser()"
        class="logout-button"
        tabindex="0"
      >
        <img alt="Logout" src="/assets/logout-white.svg" />Logout
      </button>
    </div>
    <div *ngIf="!isExpanded" class="user-login-collapsed">
      <div class="menu-item">
        <div class="icon">
          <img alt="Logout" src="/assets/logout-white.svg" />
        </div>
      </div>
    </div>
  </div>
</div>
