/**
 * Defines all different kinds of remunerated tasks hygiene aggregate types.
 *
 * !!!
 * IMPORTANT: This enum is also defined in the following repositories: inspector-app, backoffice-backend => keep the versions synchronized
 * !!!
 */
export enum RemuneratedTaskHygieneAggregateType {
  DUCT_EXAMINATIONS = 'Kanaluntersuchungen',
  AIR_MEASUREMENTS = 'Luftkeimmessungen',
  HUMIDIFIER_WATER_SAMPLES = 'Befeuchterwassproben',
}

export enum RemuneratedTaskHygieneInspectionType {
  AIR_REFERENCE_MEASUREMENTS = 'Luftkeimreferenzmessungen',
}
