import { Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { LoggerService } from './logger.service';
import { ToastService } from './toast.service';
import { UserRole } from './user-role.enum';

interface JwtPayload {
  groups?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) {}

  /**
   * Gets the current access token from MSAL
   */
  getToken(): Promise<string | null> {
    return this.msalService.instance
      .acquireTokenSilent({
        scopes: ['api://1fef3d25-758b-4fdd-94e6-e90d4b172e08/.default'],
      })
      .then((result: AuthenticationResult) => result.accessToken)
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.acquireTokenViaPopup();
        }
        this.loggerService.error('Token acquisition error:', error);
        this.toastService.show(
          'warn',
          'Token acquisition error',
          'Der Auth-Token konnte nicht abgerufen werden.'
        );
        return null;
      });
  }

  /**
   * Checks whether the user has a specific role.
   */
  hasRole(roleId: UserRole): boolean {
    const account = this.msalService.instance.getActiveAccount();
    if (!account?.idToken) return false;

    const decodedToken: JwtPayload = jwtDecode(account.idToken);
    return decodedToken.groups?.includes(roleId) ?? false;
  }

  /**
   * Get the token via pop-up if Silent Auth fails.
   */
  private acquireTokenViaPopup(): Promise<string | null> {
    return this.msalService.instance
      .acquireTokenPopup({
        scopes: ['api://1fef3d25-758b-4fdd-94e6-e90d4b172e08/.default'],
      })
      .then((result: AuthenticationResult) => result.accessToken)
      .catch((err) => {
        this.loggerService.error('Pop-Up Error:', err);
        this.toastService.show(
          'warn',
          'Pop-Up Error',
          'Das Microsoft Login-Pop-Up wurde blockiert oder es trat ein Fehler auf.'
        );
        return null;
      });
  }
}
