import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { HttpParams } from '@angular/common/http';
import { QueryParamsService } from '../../../../core/query-params.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-inspector-filter',
  standalone: true,
  imports: [
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    CalendarModule,
    ButtonComponent,
    CommonModule,
    CheckboxModule,
  ],
  templateUrl: './inspector-filter.component.html',
  styleUrl: './inspector-filter.component.scss',
})

export class InspectorFilterComponent implements OnInit {
  @Output() filterListEvent = new EventEmitter<HttpParams>();
  systemName = '';
  firstName = '';
  lastName = '';
  isActive: boolean | null = null;
  showClearButton = false;

  stateFilterItems = [
    { label: 'Aktiv', value: true },
    { label: 'Inaktiv', value: false },
    { label: 'Alle', value: null }
  ];

  constructor(
    private queryService: QueryParamsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadFilterFromQueryParams();
  }

  filterOrders() {
    const filterParams: Record<string, any> = this.createFilterObject();
    const httpParams = this.queryService.createHttpParams(filterParams);
    this.filterListEvent.emit(httpParams);
  }

  clearFilter() {
    const filterParams: Record<string, any> = {};
    const httpParams = this.queryService.getCurrentQueryParams();

    const page = httpParams.get('page');
    const size = httpParams.get('size');

    if (page) {
      filterParams['page'] = page;
    }

    if (size) {
      filterParams['size'] = size;
    }

    filterParams['active'] = true;
    this.isActive = true;

    const filter: HttpParams =
      this.queryService.convertQueryParamsToHttpParams(filterParams);

    this.filterListEvent.emit(filter);
    this.showClearButton = false;
  }

  private createFilterObject(): Record<string, any> {
    const filterParams: Record<string, any> = {};
    const httpParams = this.queryService.getCurrentQueryParams();

    const page = httpParams.get('page');
    const size = httpParams.get('size');

    if (page) {
      filterParams['page'] = page;
    }

    if (size) {
      filterParams['size'] = size;
    }

    if (this.lastName) {
      filterParams['lastName'] = this.lastName;
    }

    if (this.firstName) {
      filterParams['firstName'] = this.firstName;
    }

    filterParams['active'] = this.isActive;

    return filterParams;
  }

  private loadFilterFromQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      const httpParams =
        this.queryService.convertQueryParamsToHttpParams(params);

      this.systemName = httpParams.get('systemName') || '';
      this.lastName = httpParams.get('lastName') || '';
      this.firstName = httpParams.get('firstName') || '';

      const activeParam = httpParams.get('active');
      this.isActive =
        activeParam === 'true' ? true : activeParam === 'false' ? false : null;
    });
  }
}
