/* eslint-disable no-useless-escape */
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LoggerService } from '../../../../core/logger.service';
import { ToastService } from '../../../../core/toast.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly _apiUrlOrderExcelTemplate = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/orderExcel/template`;

  private readonly _apiUrlUploadOrderExcel = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/orderExcel/`;

  private readonly _apiUrlDownloadProductListingExcelTemplate = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/productListing/extern/template`;

  private readonly _apiUrlConvertProductListingExcel = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/productListing/extern/convert`;

  private readonly _apiUrlDownloadSingleReport = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/aggregates`;

  private readonly _apiUrlDownloadOrder = `${
    import.meta.env.NG_APP_API_URL
  }/backoffice/v1/orders`;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private toastService: ToastService
  ) {}

  downloadOrderExcelTemplateFile(): Observable<HttpResponse<Blob>> {
    return this.http
      .get(this._apiUrlOrderExcelTemplate, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((err) => this.handleError(err, 'Download fehlgeschlagen!')));
  }

  uploadOrderExcelFile(file: FormData): Observable<any> {
    return this.http
      .post(this._apiUrlUploadOrderExcel, file)
      .pipe(catchError((err) => this.handleError(err, 'Upload fehlgeschlagen!')));
  }

  downloadProductListingExcelTemplateFile(): Observable<HttpResponse<Blob>> {
    return this.http
      .get(this._apiUrlDownloadProductListingExcelTemplate, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((err) => this.handleError(err, 'Download fehlgeschlagen!')));
  }

  convertProductListingExcelFile(file: FormData): Observable<HttpResponse<Blob>> {
    return this.http
      .post(this._apiUrlConvertProductListingExcel, file,
        { responseType: 'blob', observe: 'response' }
      )
      .pipe(catchError((err) => this.handleError(err, 'Upload fehlgeschlagen!')));
  }

  downloadSingleHygieneAggregateReport(
    aggregateId: string
  ): Observable<HttpResponse<Blob>> {
    return this.http
      .get(
        `${this._apiUrlDownloadSingleReport}/${aggregateId}/artifacts/hygiene/report`,
        { responseType: 'blob', observe: 'response' }
      )
      .pipe(catchError((err) => this.handleError(err, 'Download Hygiene-Bericht fehlgeschlagen!')));
  }

  downloadOrderReport(orderId: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this._apiUrlDownloadOrder}/${orderId}/artifacts`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError((err) => this.handleError(err, 'Download von Auftragsartifakten fehlgeschlagen!')));
  }

  generateArtifacts(orderId: string): Observable<any> {
    return this.http
      .post(`${this._apiUrlDownloadOrder}/${orderId}/artifacts/generate`, {})
      .pipe(catchError((err) => this.handleError(err, 'Generieren der Artifakte fehlgeschlagen!')));
  }

  extractFilename(contentDisposition: string): string {
    let filename = 'report.xlsx';

    const utf8FilenameRegex = /filename\*\=UTF\-8\'\'(.+?)(;|$)/;
    const matchesUtf8 = utf8FilenameRegex.exec(contentDisposition);

    if (matchesUtf8 && matchesUtf8[1]) {
      filename = decodeURIComponent(matchesUtf8[1]);
    } else {
      const filenameRegex = /filename\=\"(.+?)\"/;
      const matchesFilename = filenameRegex.exec(contentDisposition);

      if (matchesFilename && matchesFilename[1]) {
        filename = matchesFilename[1];
      }
    }

    return filename;
  }

  private handleError(error: HttpErrorResponse, s = 'Ein Fehler ist aufgetreten!'): Observable<never> {
    let errorMessage = 'Ein unbekannter Fehler ist aufgetreten!';

    if (error.error instanceof ErrorEvent) {
      // Client-seitiger Fehler
      errorMessage = `Error: ${error.message}`;
    } else {
      // Server-seitiger Fehler
      if (typeof error.error === 'string') {
        errorMessage = `Error Code: ${error.status} \nMessage: ${error.error}`;
      } else if (error.error && error.error.error) {
        errorMessage = `Error Code: ${error.status} \nMessage: ${error.error.error}`;
      } else if (error.error && typeof Blob) {
        this.handleBlobError(error);
      } else {
        errorMessage = `Error Code: ${error.status} \nMessage: ${error.message}`;
      }
    }

    this.loggerService.error(errorMessage);
    this.toastService.show('warn', s, errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  handleBlobError(error: HttpErrorResponse) {
    error.error.text().then((res: string) => {
      const blobError = JSON.parse(res);
      const errorMessage = `Error Code: ${error.status}\nMessage: ${blobError.error}`;
      this.loggerService.error(errorMessage);
      this.toastService.show(
        'warn',
        'Ein Fehler ist aufgetreten',
        errorMessage
      );
      return throwError(() => new Error(errorMessage));
    });
  }
}
