<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [ngStyle]="currentStyles"
  class="card"
>
  <div class="card-text">
    <div class="title-text">
      <h3>{{ title }}</h3>
      @if (zohoId) {
        <a
          [href]="
            'https://crm.zoho.eu/crm/org20093979379/tab/Accounts/' + zohoId
          "
          target="_blank"
          (keyup.enter)="navigateToZoho(zohoId)"
          (keyup.space)="navigateToZoho(zohoId)"
          tabindex="0"
          >Zoho</a
        >
      }
    </div>

    <ng-content></ng-content>
  </div>
</div>
