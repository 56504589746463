<table class="custom-table">
  <thead>
  <tr>
    @for (column of columns; track $index) {
      <th *ngIf="column.key !== 'stock' || hasUserRole(UserRole.BACKOFFICE_STOCK_MANAGER)" [ngClass]="'align-' + column.align">
        <div [ngClass]="'table-head-' + column.align">
          {{ column.label }}
          @if (column.allowSort) {
            @if (sortColumn === column.key) {
              <img
                class="active"
                (click)="setSort(column.key)"
                (keyup.enter)="setSort(column.key)"
                (keyup.space)="setSort(column.key)"
                tabindex="0"
                [src]="
                    'assets/filter-' +
                    (sortDirection === SortDirection.ASC ? 'asc' : 'desc') +
                    '.svg'
                  "
                alt="Filter Icon"
              />
            } @else {
              <img
                (click)="setSort(column.key)"
                (keyup.enter)="setSort(column.key)"
                (keyup.space)="setSort(column.key)"
                tabindex="0"
                src="../../../../../assets/filter.svg"
                alt="Filter Icon"
              />
            }
          }
        </div>
      </th>
    }
  </tr>
  </thead>
  <tbody>
    @for (inspector of sortedData.length ? sortedData : inspectorsList; track inspector.id) {
      <tr>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ inspector.firstName ? inspector.firstName : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ inspector.lastName ? inspector.lastName : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ inspector.systemName ? inspector.systemName : '-' }}</span>
          </div>
        </td>
        <td class="iconColumn">
          <img [src]="getActiveIcon(inspector.active)" />
        </td>
        <td *ngIf=" hasUserRole(UserRole.BACKOFFICE_STOCK_MANAGER)" class="iconColumn">
          <img [src]="'/assets/stock.svg'" (click)="toggleStock(inspector.systemName)" style="cursor: pointer;" />
        </td>
      </tr>
      <tr *ngIf="showStock[inspector.systemName]">
        <td colspan="9">
      <tr *ngIf="showStock[inspector.systemName]" style="display: grid;">
        <td colspan="9" style="padding: 0">
          <p-table
            [value]="inspectorStockData[inspector.systemName] || []"
            class="inspector-stock-table"
          >
            <ng-template pTemplate="header">
              <tr>
                <th class="align-left">Aktualität</th>
                <th class="align-left">Datum</th>
                <th class="align-center">AK-Caso</th>
                <th class="align-center">AK-DG18</th>
                <th class="align-center">AK-MEA</th>
                <th class="align-center">LK-Caso</th>
                <th class="align-center">LK-DG18</th>
                <th class="align-center">LK-MEA</th>
                <th class="align-center">Wasserflaschen</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-stock>
              <tr>
                <td>{{ stock.actuality }}</td>
                <td>{{ stock.reportedAt }}</td>
                <td class="align-center">{{ stock.amountWipeCaso }}</td>
                <td class="align-center">{{ stock.amountWipeDg18 }}</td>
                <td class="align-center">{{ stock.amountWipeMea }}</td>
                <td class="align-center">{{ stock.amountAirCaso }}</td>
                <td class="align-center">{{ stock.amountAirDg18 }}</td>
                <td class="align-center">{{ stock.amountAirMea }}</td>
                <td class="align-center">{{ stock.amountWaterBottles }}</td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="9" class="align-center">Keine Daten vorhanden.</td>
              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    }
  </tbody>
  <tfoot>
  <tr>
    <td colspan="8">
      <div class="pagination-controls">
        <p-paginator
          (onPageChange)="onPageChange($event)"
          [first]="first"
          [rowsPerPageOptions]="itemsPerPageOptions"
          [rows]="itemsPerPageShown"
          [totalRecords]="totalRecords"
        />
      </div>
    </td>
  </tr>
  </tfoot>
</table>



