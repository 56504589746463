import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import {
  InspectorResponse,
  InspectorStockResponse,
} from '../../../models/order/inspector-response.model';
import { SortDirection } from '../../orders/table/sort-direction.enum';
import { QueryParamsService } from '../../../../core/query-params.service';
import { HttpParams } from '@angular/common/http';
import { PaginatorModule } from 'primeng/paginator';
import { InspectorService } from '../../../../core/inspector.service';
import { TableModule } from 'primeng/table';
import { UserRole } from '../../../../core/user-role.enum';
import { TokenService } from '../../../../core/token.service';

@Component({
  selector: 'app-inspector-table',
  standalone: true,
  imports: [
    NgIf,
    TabViewModule,
    NgClass,
    PaginatorModule,
    TableModule,
  ],
  templateUrl: './inspector-table.component.html',
  styleUrl: './inspector-table.component.scss',
})
export class InspectorTableComponent implements OnChanges {
  @Input() inspectorsList: InspectorResponse[] = [];
  @Input() totalPages = 0;

  totalRecords = 0;

  @Output() paginationChangeEvent = new EventEmitter<{
    params: HttpParams;
  }>();

  itemsPerPageOptions = [5, 10, 25, 50, 100];
  itemsPerPageShown = this.itemsPerPageOptions[2];
  first = 0;

  currentPage = 1;
  sortColumn = '';
  sortDirection: SortDirection = SortDirection.NONE;
  SortDirection = SortDirection;

  sortedData: InspectorResponse[] = this.inspectorsList;

  showStock: Record<string, boolean> = {};

  columns = [
    { key: 'firstName', label: 'Vorname', allowSort: true, align: 'left' },
    { key: 'lastName', label: 'Nachname', allowSort: true, align: 'left' },
    { key: 'systemName', label: 'E-Mail', allowSort: true, align: 'left' },
    { key: 'active', label: 'Account Status', allowSort: false, align: 'center' },
    { key: 'stock', label: 'Materialbestand', allowSort: false, align: 'center' },
  ];

  inspectorStockData: Record<string, InspectorStockResponse[]> = {};
  protected readonly UserRole = UserRole;

  constructor(
    private queryService: QueryParamsService,
    private inspectorService: InspectorService,
    private tokenService: TokenService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const http = this.queryService.getCurrentQueryParams();

    this.updateSortParams(http);
    this.updatePaginationParams(http);
    this.handleInspectorListChange(changes);
  }

  setSort(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection =
        this.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : this.sortDirection === SortDirection.DESC
            ? SortDirection.NONE
            : SortDirection.ASC;
      if (this.sortDirection === SortDirection.NONE) {
        this.sortColumn = '';
      }
    } else {
      this.sortColumn = column;
      this.sortDirection = SortDirection.ASC;
    }
    this.setSortingParams(this.sortColumn, this.sortDirection);
  }

  getActiveIcon(isActive: boolean): string {
    return isActive ? 'assets/active.svg' : 'assets/inactive.svg';
  }

  toggleStock(systemName: string): void {
    if (this.hasUserRole(UserRole.BACKOFFICE_STOCK_MANAGER)) {
      if (!this.showStock[systemName]) {
        this.getInspectorStock(systemName);
      }
      this.showStock[systemName] = !this.showStock[systemName];
    }
  }

  hasUserRole(role: UserRole): boolean {
    return this.tokenService.hasRole(role);
  }

  getInspectorStock(owner: string) {
    this.inspectorService.getInspectorsStock(owner).subscribe((response) => {
      this.inspectorStockData[owner] = response
        .filter(item => item.reportedAt != null)
        .map(item => ({
          ...item,
          reportedAt: this.formatDate(item.reportedAt),
          actuality: this.calculateActuality(item.reportedAt)
        }));
    });
  }

  setSortingParams(sortKey: string, sortDirection: string): void {
    let currentHttpParams = this.queryService.getCurrentQueryParams();
    currentHttpParams =
      sortDirection !== SortDirection.NONE
        ? currentHttpParams
            .set('sortKey', sortKey)
            .set('sortDirection', sortDirection)
        : currentHttpParams.delete('sortKey').delete('sortDirection');
    this.paginationChangeEvent.emit({
      params: currentHttpParams,
    });
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.currentPage = event.page;
    this.itemsPerPageShown = event.rows;

    let http: HttpParams = this.queryService.getCurrentQueryParams();

    http = http
      .set('page', this.currentPage)
      .set('size', this.itemsPerPageShown);

    this.paginationChangeEvent.emit({
      params: http,
    });
  }

  private formatDate(date: string): string {
    return new Date(date).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(',', '');
  }

  private calculateActuality(reportedAt: string): string {
      const reportedDate = new Date(reportedAt);
      const now = new Date();

      const diffMs = now.getTime() - reportedDate.getTime();
      const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      if (diffDays > 0) {
        return `${diffDays} ${diffDays === 1 ? 'Tag' : 'Tage'}`;
      } else if (diffHours > 0) {
        return `${diffHours} ${diffHours === 1 ? 'Stunde' : 'Stunden'}`;
      } else {
        return `${diffMinutes} ${diffMinutes === 1 ? 'Minute' : 'Minuten'}`;
      }
    }

  private updateSortParams(httpParams: HttpParams): void {
    const sortKeyParam = httpParams.get('sortKey');
    const sortDirectionParam = httpParams.get('sortDirection');

    this.sortDirection = this.getValidSortDirection(sortDirectionParam);
    this.sortColumn = sortKeyParam || '';
  }

  private getValidSortDirection(
    sortDirectionParam: string | null
  ): SortDirection {
    return sortDirectionParam &&
      Object.values(SortDirection).includes(sortDirectionParam as SortDirection)
      ? (sortDirectionParam as SortDirection)
      : SortDirection.NONE;
  }

  private updatePaginationParams(httpParams: HttpParams): void {
    const pageValue = httpParams.get('page');
    const shown = httpParams.get('size');

    this.currentPage = Number(pageValue) || 0;
    this.itemsPerPageShown = Number(shown) || 0;
    this.first = this.currentPage * this.itemsPerPageShown;
  }

  private handleInspectorListChange(changes: SimpleChanges): void {
    if (changes['inspectorsList'] && changes['inspectorsList'].currentValue) {
      this.totalRecords = this.totalPages * this.itemsPerPageShown;
    }
  }
}
