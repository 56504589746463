import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonComponent } from '../../button/button.component';
import { CalendarModule } from 'primeng/calendar';
import { OrderService } from '../../../../core/order.service';

import { LoggerService } from '../../../../core/logger.service';
import { OrderStatus } from '../../../models/status/order-status.enum';
import { OrderDetailResponse } from '../../../models/order/order-detail-response.model';
import { InspectorResponse } from '../../../models/order/inspector-response.model';
import { firstValueFrom } from 'rxjs';

interface Option {
  id: string | OrderStatus;
  name: string;
}

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    ButtonComponent,
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent implements OnInit, OnChanges {
  @Input() orderDetail!: OrderDetailResponse;
  @Output() orderChangedEvent = new EventEmitter();
  OrderStatus = OrderStatus;

  visible = false;

  initialStartDate: Date | null = null;
  initialEndDate: Date | null = null;
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  minDate?: Date;

  allInspectors: InspectorResponse[] = [];
  inspectorOptions: Option[] = [];
  selectedInspectorIds: string[] = [];
  originalSelectedIds: string[] = [];

  constructor(
    private orderService: OrderService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.loadInspectors();
    this.minDate = new Date();

    this.selectedStartDate = this.orderDetail.startDate
      ? new Date(this.orderDetail.startDate)
      : null;
    this.selectedEndDate = this.orderDetail.endDate
      ? new Date(this.orderDetail.endDate)
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges) {
    this.initializeDates();
    this.setInitialInspectorSelection();
  }

  buildResettableDate(
    currentDate: Date | null,
    initialDate: Date | null
  ): { value: string | null } | null {
    if (currentDate?.getDate() == initialDate?.getDate()) {
      return null;
    }
    if (currentDate === null) {
      return { value: null };
    }
    return { value: this.orderService.formatDate(currentDate) };
  }

  save() {
    this.visible = false;
    this.updateOrder();
    this.loggerService.log(
      `Order detail ${this.orderDetail.orderNumber} was updated.`
    );
  }

  cancel(): void {
    this.selectedStartDate = this.initialStartDate;
    this.selectedEndDate = this.initialEndDate;
    this.selectedInspectorIds = [...this.originalSelectedIds];
    this.visible = false;
    this.loggerService.log(
      `Cancel update of order detail ${this.orderDetail.orderNumber}.`
    );
  }

  showDialog() {
    this.visible = true;
  }

  private loadInspectors(): void {
    this.orderService.getAllActiveInspectors().subscribe({
      next: (inspectors) => {
        this.allInspectors = inspectors;
        this.inspectorOptions = this.transformInspectorsToOptions(inspectors);
        this.setInitialInspectorSelection();
      },
      error: () => {
        this.loggerService.error('Failed to fetch inspector list');
      },
    });
  }

  private transformInspectorsToOptions(
    inspectors: InspectorResponse[]
  ): Option[] {
    return inspectors.map((inspector) => ({
      id: inspector.id,
      name: `${inspector.firstName} ${inspector.lastName}`,
    }));
  }

  private setInitialInspectorSelection(): void {
    this.originalSelectedIds = this.orderDetail.inspectors
      .filter((inspector) =>
        this.inspectorOptions.some((option) => option.id === inspector.id)
      )
      .map((inspector) => inspector.id);

    this.selectedInspectorIds = [...this.originalSelectedIds];
  }

  private initializeDates(): void {
    if (this.orderDetail.startDate) {
      this.initialStartDate = new Date(this.orderDetail.startDate);
    } else {
      this.initialStartDate = null;
    }
    if (this.orderDetail.endDate) {
      this.initialEndDate = new Date(this.orderDetail.endDate);
    } else {
      this.initialEndDate = null;
    }
    this.selectedStartDate = this.initialStartDate;
    this.selectedEndDate = this.initialEndDate;
  }

  private async updateOrder(): Promise<void> {
    try {
      await firstValueFrom(
        this.orderService.planOrder(
          this.orderDetail.id,
          this.buildResettableDate(
            this.selectedStartDate,
            this.initialStartDate
          ),
          this.buildResettableDate(this.selectedEndDate, this.initialStartDate),
          this.selectedInspectorIds
        )
      );
      this.orderChangedEvent.emit();
    } catch (error) {
      this.loggerService.error('Failed to update order', error);
    }
  }
}
