@if (toastMessage) {
  <div
    tabindex="0"
    class="toast"
    [ngClass]="toastMessage.type"
    (click)="closeToast()"
  >
    @if (toastMessage.type === 'warn') {
      <img src="/src/assets/warning.svg" alt="" />
    }
    @if (toastMessage.type === 'success') {
      <img src="/src/assets/success.svg" alt="" />
    }

    <div class="toastText">
      <h6>{{ toastMessage.title }}</h6>
      <p>{{ toastMessage.message }}</p>
    </div>
  </div>
}
