<table class="custom-table">
  <thead>
  <tr>
    @for (column of columns; track $index) {
      <th [ngClass]="'align-' + column.align">
        <div [ngClass]="'table-head-' + column.align">
          <span>{{ column.label }}</span>
          @if (sortColumn === column.key) {
            <img
              class="active"
              (click)="setSort(column.key)"
              (keyup.enter)="setSort(column.key)"
              (keyup.space)="setSort(column.key)"
              tabindex="0"
              [src]="'assets/filter-' + (sortDirection === 'asc' ? 'asc' : 'desc') + '.svg'"
              alt="Filter Icon"
            />
          } @else {
            <img
              (click)="setSort(column.key)"
              (keyup.enter)="setSort(column.key)"
              (keyup.space)="setSort(column.key)"
              tabindex="0"
              src="../../../../../assets/filter.svg"
              alt="Filter Icon"
            />
          }
        </div>
      </th>
    }
  </tr>
  </thead>
  <tbody>
    @for (aggregate of sortedData.length ? sortedData : aggregates; track aggregate.id) {
      <tr>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.idrId ? aggregate.idrId : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.name ? aggregate.name : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.type ? getLabel(aggregate.type) : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            @if (aggregate.buildingName || aggregate.floor || aggregate.installationSite) {
              <span class="bold-styling">{{ aggregate.buildingName }}</span>
              <span>{{ aggregate.floor }}</span>
              <span>{{ aggregate.installationSite }}</span>
            } @else {
              -
            }
          </div>
        </td>
        <td class="responsive-cell">
          <div class="cell-styling">
            <span>{{ aggregate.customerProvidedId ? aggregate.customerProvidedId : '-' }}</span>
          </div>
        </td>
        <td class="responsive-cell">
          <span>{{ aggregate.hygiene?.inspectionDate ? (aggregate.hygiene?.inspectionDate | date:'dd.MM.yyyy') : '-' }}</span>
        </td>
        <td>
          @if (aggregate.hygiene?.status) {
            <app-chip [status]="aggregate.hygiene?.status" />
          } @else {
            -
          }
        </td>
        <td class="align-middle">
          @if (aggregate.aggregateReport?.reportId && aggregate.aggregateReport?.reportState === AggregateReportState.GENERATED) {
            <div class="cell-styling link">
              <a (click)="downloadSingleReport(aggregate.id)" (keyup.enter)="downloadSingleReport(aggregate.id)" (keyup.space)="downloadSingleReport(aggregate.id)" tabindex="0">{{ aggregate.aggregateReport?.reportId }}</a>
            </div>
          } @else if (aggregate.aggregateReport?.reportId && aggregate.aggregateReport?.reportState === AggregateReportState.NOT_GENERATED) {
            <span>{{ aggregate.aggregateReport?.reportId }}</span>
          } @else {
            -
          }
        </td>
        <td>
          <img [src]="getCommentImage(aggregate)" (click)="toggleComment(aggregate.id)" style="cursor: pointer;" />
        </td>
        <td>
          <img [src]="getInfoImage(aggregate)" (click)="toggleDetails(aggregate)" style="cursor: pointer;" />
        </td>
      </tr>
      <tr *ngIf="showComment[aggregate.id]">
        <td colspan="10">
          <p-tabView>
            <p-tabPanel header="Anlagen Kommentare">
              <p>
                @if (aggregate.noteInternalBackoffice){
                  <app-comment-detail-card
                    title="Backoffice"
                    (click)="openCommentDialog(aggregate.id, aggregate.noteInternalBackoffice, commentType.AGGREGATE_BACKOFFICE_COMMENT)"
                    tabindex="0"
                  ><p>{{aggregate.noteInternalBackoffice}}</p>
                  </app-comment-detail-card>
                }
                @if (aggregate.noteInternalInspector){
                  <app-comment-detail-card
                    title="Techniker"
                    tabindex="0"
                  ><p>{{aggregate.noteInternalInspector}}</p>
                  </app-comment-detail-card>
                }
                @if (!aggregate.noteInternalBackoffice){
                  <app-button
                    (click)="openCommentDialog(aggregate.id, aggregate.noteInternalBackoffice, commentType.AGGREGATE_BACKOFFICE_COMMENT)"
                    type="secondary-icon-button"
                    icon="/assets/add.svg"
                    label="Neuer Backoffice Kommentar"
                  />
                }
              </p>
            </p-tabPanel>
            <p-tabPanel header="Hygieneinspektions Kommentare">
              <p>
                @if (aggregate.hygiene?.noteInternalBackoffice){
                  <app-comment-detail-card
                    title="Backoffice"
                    (click)="openCommentDialog(aggregate.id, aggregate.hygiene?.noteInternalBackoffice, commentType.HYGIENE_BACKOFFICE_COMMENT)"
                    tabindex="0"
                  ><p>{{aggregate.hygiene?.noteInternalBackoffice}}</p>
                  </app-comment-detail-card>
                }
                @if (aggregate.hygiene?.noteInternalInspector){
                  <app-comment-detail-card
                    title="Techniker"
                    tabindex="0"
                  ><p>{{aggregate.hygiene?.noteInternalInspector}}</p>
                  </app-comment-detail-card>
                }
                @if (aggregate.hygiene?.noteExternalReport){
                  <app-comment-detail-card
                    title="Bericht"
                    tabindex="0"
                  ><p>{{aggregate.hygiene?.noteExternalReport}}</p>
                  </app-comment-detail-card>
                }
                @if (!aggregate.hygiene?.noteInternalBackoffice){
                  <app-button
                    (click)="openCommentDialog(aggregate.id, aggregate.hygiene?.noteInternalBackoffice, commentType.HYGIENE_BACKOFFICE_COMMENT)"
                    type="secondary-icon-button"
                    icon="/assets/add.svg"
                    label="Neuer Backoffice Kommentar"
                  />
                }
              </p>
            </p-tabPanel>
          </p-tabView>
        </td>
      </tr>
      <tr *ngIf="showDetails[aggregate.id]">
        <td colspan="10">
          <p-tabView>
            @if (hasCancelReason(aggregate)){
            <p-tabPanel header="Abbruchgrund">
              <app-notification-card>
                <p>{{ aggregate.hygiene?.cancelReason
                  ? (aggregate.hygiene?.cancelReason + (aggregate.hygiene?.cancelReasonComment ? ': ' + aggregate.hygiene?.cancelReasonComment : ''))
                  : 'Kein Abbruchgrund angegeben' }}</p>
              </app-notification-card>
            </p-tabPanel>
            }
            @if (hasValidTasks(aggregate)){
              <p-tabPanel header="Beauftragungen">
                <p-accordion>
                  <p-accordionTab [header]="getTaskLabel(task.type) + ' (' + task.done + ' / ' + task.ordered + ')'"
                                  *ngFor="let task of aggregate.hygiene?.remuneratedTasks" [disabled]="!task.notAchievableReason">
                    <p>
                      <app-notification-card>
                        <p>{{ task.notAchievableReason }}</p>
                      </app-notification-card>
                    </p>
                  </p-accordionTab>
                </p-accordion>
            </p-tabPanel>
            }
          </p-tabView>
        </td>
      </tr>
    }
  </tbody>
  <tfoot>
  <tr>
    <td colspan="8"></td>
  </tr>
  </tfoot>
</table>

<app-comment-dialog
  (orderChangedEvent)="updateDetailOnChange()"
/>


