<div class="order-headline">
  <h2>Techniker</h2>
</div>
@if (isLoading) {
  <app-loading-animation />
}
<app-inspector-filter
  (filterListEvent)="filterAllInspectors($event)"
  (httpParams)="(filterValues)"
/>
@if (!isLoading && error === null) {
  <app-inspector-table
    [inspectorsList]="inspectorsList"
    [totalPages]="totalPages"
    (paginationChangeEvent)="filterAllInspectors($event.params)"
  />
}
@if (error) {
  <div class="error-container"><app-error [error]="error" /></div>
}
