import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { OrderService } from '../../../../core/order.service';
import { LoggerService } from '../../../../core/logger.service';
import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-comment-dialog',
  standalone: true,
  imports: [ButtonComponent, InputTextareaModule, DialogModule, FormsModule],
  templateUrl: './comment-dialog.component.html',
  styleUrl: './comment-dialog.component.scss',
})
export class CommentDialogComponent {
  @Output() orderChangedEvent = new EventEmitter();
  visible = false;
  comment: string | undefined = '';
  id!: string;
  type!: string;

  constructor(
    private orderService: OrderService,
    private loggerService: LoggerService
  ) {}

  cancel(): void {
    this.visible = false;
    this.loggerService.log(`Cancel update of order comment ${this.id}.`);
  }

  delete() {
    this.visible = false;
    this.updateOrderComment('');
    this.loggerService.log(`Delete comment of order ${this.id}.`);
  }

  save() {
    this.visible = false;
    this.updateOrderComment(this.comment);
    this.loggerService.log(`Order comment ${this.id} was updated.`);
  }

  showDialog(
    id: string,
    comment: string | undefined,
    type: string
  ) {
    this.id = id;
    this.comment = comment;
    this.type = type;
    this.visible = true;
  }

  private async updateOrderComment(comment: any): Promise<void> {
    try {
      await firstValueFrom(
        this.orderService.updateOrderComment(this.id, comment, this.type)
      );
      this.orderChangedEvent.emit();
    } catch (error) {
      this.loggerService.error('Failed to update order', error);
    }
  }
}
