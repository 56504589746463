/**
 * Defines all different kinds of comment types.
 *
 * !!!
 * IMPORTANT: This enum is also defined in the following repositories: backoffice-backend => keep the versions synchronized
 * !!!
 */
export enum CommentType {
  ORDER_BACKOFFICE_COMMENT = 'ORDER_BACKOFFICE_COMMENT',
  AGGREGATE_BACKOFFICE_COMMENT = 'AGGREGATE_BACKOFFICE_COMMENT',
  HYGIENE_BACKOFFICE_COMMENT = 'HYGIENE_BACKOFFICE_COMMENT',
}

